@use '@/styles/utils/mixins.scss' as *;

.serviceSectionWrapper {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  .sectionHeadingContainer {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    justify-content: space-between;
  }
  .sectionDescription {
    max-width: 50%;
    @include tab() {
      max-width: 100%;
    }
  }
  .cardsList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5rem;
    @include mob() {
      grid-template-columns: repeat(1, 1fr);
    }

    .productCard {
      height: 100%;
      min-height: 20rem;
      border-radius: 1.25rem;
      -webkit-box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
      -moz-box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
      box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
      padding: 2.5rem 1.875rem;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      background-color: $white;
      border: 0.063rem solid $white;
      transition: all 0.2s ease-in-out;

      &:hover {
        -webkit-box-shadow: 0rem 0.375rem 1rem 0rem rgba($primaryAlt, 0.35);
        -moz-box-shadow: 0rem 0.375rem 1rem 0rem rgba($primaryAlt, 0.35);
        box-shadow: 0rem 0.375rem 1rem 0rem rgba($primaryAlt, 0.35);
        border-color: $primaryAlt;
      }

      .productCardBody {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;
      }
      .description {
        color: $neutral08;
      }
      :global {
        .headingElement {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 0.25rem;
          word-wrap: break-all;
        }
      }
    }
  }
}
